
<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import Svelecte from "svelecte/src/Svelecte.svelte"
    import SelectItem from "@shared/Form/WeatherTypeSelectType/SelectItem.svelte"

    export let entry: FormEntry
    export let i18n = {
        empty: 'Bez možností',
        nomatch: 'Nic jsme nenašli',
        max: num => `Maximum: ${num}`,
        fetchBefore: 'Začněte psát',
        fetchQuery: (minQuery, inputLength) => `Napište ${minQuery > 1 && minQuery > inputLength
                ? `ještě alespoň ${minQuery - inputLength} znaků `
                : ''}pro vyhledávání`,
        fetchInit: 'Hledáme...',
        fetchEmpty: 'Nic jsme nenašli',
        collapsedSelection: count => `Vybráno: ${count}`,
        createRowLabel: value => `Vytvořit: '${value}'`
    }

    let containerEl: HTMLElement

    const handleChange = (): void => {
        const select = containerEl?.querySelector("select")
        if (select) {
            select.dispatchEvent(new Event("change", {
                composed: true,
                bubbles: true,
                cancelable: true,
            }))
        }
    }

    const handleCreateItem = (e: CustomEvent<{$created: boolean, label: string, value: string}>): void => {
        if(e.detail.$created) {
            entry.value = e.detail.value
        }
    }
</script>


<div {...entry.rowAttr} bind:this={containerEl} class="flex flex-col">
    {#if entry.label !== null}
        <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
            {@html entry.label}
            {#if !entry.disabled && entry.required}
                <span class="absolute ml-1 text-red-800">*</span>
            {/if}
        </label>
    {/if}

    <Svelecte
            {i18n}
            placeholder={entry.disabled ? 'Není vybráno' : 'Vyberte'}
            {...entry.attr}
            bind:value={entry.value}
            class="svelecte-control shadow-sm mt-1 {entry.disabled ? 'msd-svelecte-disabled' : ''}"
            clearable={true}
            disabled={entry.disabled}
            inputId={entry.id}
            labelField="label"
            multiple={entry.multiple}
            name={entry.fullName}
            on:change={handleChange}
            on:createoption={handleCreateItem}
            options={entry.choices}
            style="
                --sv-min-height: 36px;
                --sv-bg: white;
                --sv-border: none;
                --sv-active-border: none;
            "
            valueField="value"
            dropdownItem={SelectItem}
            controlItem={SelectItem}
    />

    <FormTypeErrors errors={entry.errors}/>

    {#if entry.help}
        <small class="mt-3">{@html entry.help}</small>
    {/if}
</div>
